import {
    FETCH_DEFAULT_CATEGORIES, FETCH_DEFAULT_GOODS,
    FETCH_DEFAULT_SUBCATEGORIES, FETCH_GOODS_BY_SUBCATEGORY,
    SELECT_CATEGORY, SELECT_SUBCATEGORIES_BY_CATEGORY, SELECT_SUBCATEGORY
} from "../actions/actionTypes";

const initialState = {
    defaultCategories: [],
    defaultSubcategories: [],
    subcategories: [],
    category: {},
    subcategory: {},
    goodsBySubcategory: []
}

export const categoriesReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_DEFAULT_CATEGORIES :
            return {
                ...state,
                defaultCategories: action.categories,
            }
        default:
            return state;
    }
};

export const subcategoriesReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_DEFAULT_SUBCATEGORIES :
            return {
                ...state,
                defaultSubcategories: action.subcategories,
                subcategories: action.subcategories
            }
        case SELECT_SUBCATEGORIES_BY_CATEGORY :
            return {
                ...state,
                subcategories: action.subcategories
            }
        default:
            return state;
    }
};

export const selectCategoryReducer = (state = initialState, action) => {

    switch (action.type) {
        case SELECT_CATEGORY :
            return {
                ...state,
                category: action.category
            }
        default:
            return state;
    }
};
export const selectSubcategoryReducer = (state = initialState, action) => {
    switch (action.type) {
        case SELECT_SUBCATEGORY :
            return {
                ...state,
                subcategory: action.subcategory
            }
        default:
            return state;
    }
};

export const goodsBySubcategoryReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_DEFAULT_GOODS :
            return {
                ...state,
                goodsBySubcategory: action.goodsBySubcategory
            }
        case FETCH_GOODS_BY_SUBCATEGORY :
            return {
                ...state,
                goodsBySubcategory: action.goodsBySubcategory
            }
        default:
            return state;
    }
};


