// action creators
import {
    ADD_TO_CART,
    CLOSE_AUTH_MODAL, CLOSE_REG_MODAL,
    FETCH_DEFAULT_CATEGORIES, FETCH_DEFAULT_GOODS,
    FETCH_DEFAULT_SUBCATEGORIES, FETCH_GOODS_BY_SUBCATEGORY,
    LOGIN, LOGOUT, OPEN_AUTH_MODAL, OPEN_REG_MODAL,
    SELECT_CATEGORY, SELECT_SUBCATEGORIES_BY_CATEGORY, SELECT_SUBCATEGORY, SET_GOOD_COUNTER,
} from "./actionTypes.js";
import {GET_USER_ADDRESSES, REMOVE_GOOD_FROM_CART, SET_CART_FROM_API, SET_ORDER_FROM_API} from "./actionTypes";
import {mainUrl} from '../config';

export const fetchDefaultCategories = () => {
    return async dispatch => {
        try {
            const response = await fetch(`${mainUrl}/shops/product-categories/?filter=for-suppliers`);
            const jsonData = await response.json();
            const defaultCategories = jsonData.results;

            await dispatch({
                type: FETCH_DEFAULT_CATEGORIES,
                categories: defaultCategories,
            });
        } catch (e) {
            console.log(e)
        }
    };
};

export const fetchDefaultSubcategories = () => {
    return async dispatch => {
        try {
            const response = await fetch(`${mainUrl}/shops/product-subcategories/?filter=for-suppliers`);
            const jsonData = await response.json();
            const defaultSubcategories = jsonData.results;

            await dispatch({
                type: FETCH_DEFAULT_SUBCATEGORIES,
                subcategories: defaultSubcategories,
            });
        } catch (e) {
            console.log(e)
        }
    };
};

export const fetchDefaultGoods = () => {
    return async dispatch => {
        try {
            const responseDefaultGoods = await fetch(`${mainUrl}/shops/products/`);
            const jsonDefaultGoods = await responseDefaultGoods.json();
            const defaultGoods = jsonDefaultGoods.results;

            await dispatch({
                type: FETCH_DEFAULT_GOODS,
                goodsBySubcategory: defaultGoods
            });
        } catch (e) {
            console.log(e)
        }
    };
};


export const selectCategory = (category) => {
    return {
        type: SELECT_CATEGORY,
        category
    }
};
export const selectSubcategory = (subcategory) => {
    return {
        type: SELECT_SUBCATEGORY,
        subcategory
    }
};


export const login = (tel) => {
    return {
        type: LOGIN,
        tel,
    }
};

export const logout = () => {
    return {
        type: LOGOUT,
    }
};

export const openAuthModal = () => {
    return {
        type: OPEN_AUTH_MODAL,
    }
};

export const closeAuthModal = () => {
    return {
        type: CLOSE_AUTH_MODAL,
    }
};

export const openRegModal = () => {
    return {
        type: OPEN_REG_MODAL,
    }
};

export const closeRegModal = () => {
    return {
        type: CLOSE_REG_MODAL,
    }
};

export const selectSubcategoriesByCategory = (subcategories) => {
    return {
        type: SELECT_SUBCATEGORIES_BY_CATEGORY,
        subcategories,
    }
};

export const fetchGoodsBySubcategory = (name) => {
    return async dispatch => {
        try {
            const response = await fetch(`${mainUrl}/shops/products/?subcategory-name=${name}`);
            const jsonData = await response.json();
            const goodsBySubcategory = jsonData.results;

            await dispatch({
                type: FETCH_GOODS_BY_SUBCATEGORY,
                goodsBySubcategory: goodsBySubcategory,
            });
        } catch (e) {
            console.log(e)
        }
    };
};

export const addToCart = (good, counter) => {
    return {
        type: ADD_TO_CART,
        good,
        counter
    }
};

export const setGoodCounter = (goodCounter, good) => {
    return {
        type: SET_GOOD_COUNTER,
        goodCounter,
        good

    }
};

export const removeGoodFromCart = (id) => {
    return {
        type: REMOVE_GOOD_FROM_CART,
        id
    }
};

export const setCartFromApi = (cartFromApi) => {
    return {
        type: SET_CART_FROM_API,
        cartFromApi
    }
};

export const setOrderFromApi = (orderFormApi) => {
    return {
        type: SET_ORDER_FROM_API,
        orderFormApi
    }
};

export const getUserAddresses = (addresses) => {
    return {
        type: GET_USER_ADDRESSES,
        addresses
    }
};



