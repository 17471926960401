import React, {useEffect} from 'react';
import Layout from "./Layout/Layout";
import Goods from "./Goods/Goods";
import Subcategories from "../components/Subcategories/Subcategories";
import Categories from "../components/Categories/Categories";
import {fetchDefaultCategories, fetchDefaultGoods, fetchDefaultSubcategories} from "../actions/actions";
import {useDispatch} from "react-redux";

const Main = () => {

    const dispatch = useDispatch();

    useEffect(() => { // подтягиваем с сервера категории и подкатегории и выводим на гоавной
        dispatch(fetchDefaultCategories());
        dispatch(fetchDefaultSubcategories());
        dispatch(fetchDefaultGoods());
    }, [dispatch]);

    return (
        <Layout>
            <Categories/>
            <Subcategories/>
            <Goods/>
        </Layout>
    );
};

export default Main;