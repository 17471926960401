import {
    CLOSE_AUTH_MODAL, CLOSE_REG_MODAL,
    OPEN_AUTH_MODAL, OPEN_REG_MODAL
} from "../actions/actionTypes";

const initialState = {
    authModal: false,
    regModal: false,
}

export const modalReducer = (state = initialState, action) => {
    switch (action.type) {
        case OPEN_AUTH_MODAL :
            return {
                ...state,
                authModal: true
            }
        case CLOSE_AUTH_MODAL :
            return {
                ...state,
                authModal: false
            }
        case OPEN_REG_MODAL :
            return {
                ...state,
                regModal: true
            }
        case CLOSE_REG_MODAL :
            return {
                ...state,
                regModal: false
            }
        default:
            return state;
    }
};