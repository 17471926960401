import React from 'react';
import './ActiveOrderItem.css';
import {getStatusOfOrder} from "../../functions";
import {Link} from "react-router-dom";

const ActiveOrderItem = ({order}) => {

    return (
        <Link
            to={'/activeOrderItemDetail'}
            state={{
                order: order
            }}
            className="ActiveOrderItem">
            <div>
                <img className="ActiveOrderItem_img" src={`https://stubla.com${order?.shop?.logo}`} alt={order?.shop?.name}/>
            </div>
            <div className="ActiveOrderItem_name">{order?.shop?.name}</div>
            <div className="ActiveOrderItem_details">
                <div className="ActiveOrderItem_total-price">{order?.order_total_price}</div>
                <div className="ActiveOrderItem_total-status">{getStatusOfOrder(order?.status)}</div>
            </div>
        </Link>
    );
};

export default ActiveOrderItem;