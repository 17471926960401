import {
    LOGIN,
    LOGOUT
} from "../actions/actionTypes";

const initialState = {
    isAuth: false,
    tel: '',
    password: '',
}

export const authReducer = (state = initialState, action) => {
    switch (action.type) {
        case LOGIN :
            return {
                ...state,
                isAuth: true,
                tel: action.tel,
            }
        case LOGOUT : {
            localStorage.removeItem('refresh');
            localStorage.removeItem('access');
            return {
                ...state,
                isAuth: false,
                tel: '',
            }
        }
        default:
            return state;
    }
};