import React from 'react';
import Layout from "../../containers/Layout/Layout";
import { useNavigate } from "react-router";
import './Profile.css';
import {useSelector} from "react-redux";

const Profile = () => {

    const navigate = useNavigate();

    const user = useSelector(state => {
        const {authReducer} = state;
        return authReducer.isAuth;
    });


    return (
        <Layout>
            <button onClick={() => navigate(-1)} className="back-btn-profile button-grey1">
               Назад
            </button>

        </Layout>
    );
};

export default Profile;