import {
    SET_CART_FROM_API, SET_ORDER_FROM_API
} from "../actions/actionTypes";

const initialState = {
    cartFromApi: [],
    orderFromApi: {}
}

export const cartFromApiReducer = (state = initialState, action) => {
        switch (action.type) {
            case SET_CART_FROM_API : {
                return {
                    ...state,
                    cartFromApi: action.cartFromApi
                }
            }
            case SET_ORDER_FROM_API : {
                return {
                    ...state,
                    orderFromApi: action.orderFormApi
                }
            }
            default:
                return state;
        }
    }
;