import React from 'react';
import {nanoid} from "nanoid";
import './CartItem.css';
import {Link} from "react-router-dom";
import {mainUrl} from '../../config';

const CartItem = (props) => {

    return (
        <Link className="CartItem-Link" to={"/order"}
              state={{
                  id: props.shop.id
              }}
        >
            <div className="CartItem"
                 key={props.shop.id}
            >
                <h4 className="CartItem_shop-name" key={props.shop.id}>{props.shop.shop?.name}</h4>
                <div className="CartItem_products">
                    <div className="CartItem_products-list">
                        <div
                        className="CartItem_products-list-text">Товары: </div>
                        {props.shop?.product_names.map(product => {
                            return <div className="CartItem-product" key={nanoid(3)}>{product}</div>
                        })}
                    </div>
                    <div className="CartItem_total_order_price">
                        <span style={{margin: '20px',fontWeight: 'bold'}}>Total price: </span>
                            {props.shop.order_total_price} &#8376;</div>
                </div>
            </div>
        </Link>
    );
};

export default CartItem;