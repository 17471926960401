import React, {useState} from 'react';
import './Register.css';
import {closeRegModal} from "../../actions/actions";
import {useDispatch} from "react-redux";
import {mainUrl} from '../../config';

//detail": "This phone number is already registered!"

const Register = () => {
        const [userData, setUserData] = useState({ // локальный стейт данных юзера
            phone_number: '',
            name: '',
            city_id: 1,
            password: '',
            password2: '',
            confirmation_code: '',
            user_type: 0,
        });
        const [hideToggle, setHideToggle] = useState(true);

        const dispatch = useDispatch();

        const sendRegistrationData = async () => { // отправляем регистрационные данные
            try {
                const response = await fetch(`${mainUrl}/register/`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({...userData})
                });
                const jsonData = await response.json();
                setUserData({ // очищаем данные юзера
                    ...this.state,
                    phone_number: '',
                    name: '',
                    city_id: 1,
                    password: '',
                    password2: '',
                    confirmation_code: '',
                })
            } catch
                (e) {
                console.log(e)
            }
        }

        const fetchConfirmationCode = async () => { // отправляем код подтверждения
            try {
                const response = await fetch(`${mainUrl}/send-code/`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({phone_number: userData.phone_number})
                });
                const jsonData = await response;
            } catch (e) {
                console.log(e)
            }
        }

        const equalPasswords = () => { // сравниваем пароли
            return userData.password === userData.password2
        }

        const [phoneNumber, setPhoneNumber] = useState('+7')
        return (
            <div className="Register">
                <div className="Register_items">
                    {hideToggle // вывод регистрации или подтверждения кода
                        ? <div className="Register_inner">
                            {/*форма регистрации*/}
                            <div
                                className="back_to_main_btn reg_back_to_main_btn button-grey1"
                                onClick={() => dispatch(closeRegModal())}
                            >Назад
                            </div>
                            <div className="Register_inner-form">
                                <form className="Register_form">
                                    <input
                                        className="Register_form-tel"
                                        onChange={event => {
                                            setUserData({...userData, phone_number: event.target.value})
                                            setPhoneNumber(event.target.value)
                                        }}
                                        value={phoneNumber}
                                        placeholder="Номер телефона"
                                        type="tel"
                                        />
                                    <input
                                        className="Register_form-name"
                                        onChange={event => {
                                            setUserData({...userData, name: event.target.value})
                                        }}
                                        placeholder="Имя"
                                        type="text"/>
                                    <select
                                        className="Register_form-select"
                                        onChange={event => {
                                            setUserData(() => {
                                                switch (event.target.value) {
                                                    case "Алматы" :
                                                        return {...userData, city_id: 1}
                                                    case "Нур-Султан" :
                                                        return {...userData, city_id: 2}
                                                    default :
                                                        break;
                                                }
                                            })
                                        }}
                                        defaultValue="Алматы">
                                        <option value="Алматы">Алматы</option>
                                        <option value="Нур-Султан">Нур-Султан</option>
                                    </select>
                                    <input
                                        className="Register_form-password"
                                        onChange={event => {
                                            setUserData({...userData, password: event.target.value})
                                        }}
                                        placeholder="Пароль" type="text"
                                        minLength="8"/>
                                    <input
                                        className="Register_form-password Register_form-password-repeat"
                                        onChange={event => {
                                            setUserData({...userData, password2: event.target.value})
                                        }}
                                        placeholder="Повторите пароль" type="text"
                                        minLength="8"/>
                                    {equalPasswords() || <p style={{'color': 'red', fontSize: 12}}>Пароли не совпадают</p>}
                                </form>
                                <p className="Register_inner-terms-of-use">Нажимая кнопку Далее вы подтверждаете, что
                                    прочитали
                                    и
                                    согласны с Пользовательским соглашением.</p>
                                <div className="Register_button">
                                    <button
                                        className="Auth_signin"
                                        onClick={() => {
                                            fetchConfirmationCode();
                                            setHideToggle(false)
                                        }}
                                        disabled={!userData.phone_number || !userData.password || !userData.password2 || !userData.name}
                                    >Далее
                                    </button>
                                </div>
                            </div>
                        </div>
                        :
                        // форма подтверждения кода
                        <div className="Register_confirmation">
                            <button onClick={() => setHideToggle(true)}
                                    className="back_to_main_btn reg_back_to_main_btn">Назад
                            </button>
                            <div className="Register_confirmation-form">
                                <p>Введите код подтверждения:</p>
                                <input
                                    type="text"
                                    className="Register_confirmation-input"
                                    onChange={event => {
                                        setUserData({...userData, confirmation_code: event.target.value})
                                    }}
                                    placeholder="Код подтверждения"
                                />
                                <button
                                    className="Register_confirmation-btn button-yellow"
                                    onClick={() => {
                                        sendRegistrationData(); // отправляем код подтверждения
                                        dispatch(closeRegModal()) // закрываем модалку с регистрацией
                                    }}
                                >Подтвердить
                                </button>
                                <div/>
                            </div>
                        </div>
                    }
                </div>
            </div>
        );
    }
;

export default Register;