import React from 'react';
import './CategoryItem.css'


const CategoryItem = ({category, id, onSelectCategory}) => {

    let imageSrcHTTP = category.image
    let imageSrcHTTPS = imageSrcHTTP.replace('http', 'https')

    return (
        <div className="CategoryItem"
             key={id}
             onClick={() => {onSelectCategory(category)
            }} // выбираем нужную категорию
        >
            <img className="CategoryItem_pic" src={imageSrcHTTPS} alt={category.name}/>
            <div className="CategoryItem_name">{category.name}</div>
        </div>
    );
};

export default CategoryItem;