import React, {useEffect, useState} from 'react';
import Layout from "../../containers/Layout/Layout";
import {Link, useLocation} from "react-router-dom";
import './Payment.css';
import {mainUrl} from '../../config';
import {fetchWithAuthCheck} from "../../fetches";
import {setCartFromApi} from "../../actions/actions";

const Payment = () => {

    const [payment, setPayment] = useState({});
    const [paymentData, setPaymentData] = useState([]);
    const [card, setCard] = useState({});
    const [paymentSucceeded, setPaymentSucceeded] = useState(false);

    const location = useLocation();

    const orderId = location.state.id;
    const totalPrice = location.state.price;

    const fetchPaymentBySavedCard = async () => { // платеж по сохраненной карте
        // получаем токен для платежа
        const paymentToken = await fetchWithAuthCheck(`/shops/halykbank-checkout/`, {
            method: 'POST',
            body: JSON.stringify({
                order_id: orderId
            })
        })
            .then(async response => {
                    const jsonData = await response.json();
                    console.log(jsonData)
                    return jsonData.token?.access_token;
                }
            );

        try {
            // совершаем платеж по сохраненной карте
            const response = await fetch(`${mainUrl}/shops/saved-method-checkout/`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('access')}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    "order_id": orderId,
                    "card_id": card.card_id,
                    "cardholder_name": card.cardholder_name,
                    "token": paymentToken
                })
            });

            const jsonData = await response.json();
            if (jsonData.message === "Payment succeeded!") {
                setPaymentSucceeded(true);
            }
        } catch (e) {
            console.log(e)
        }
    };

    useEffect(async () => {
        // fetchPaymentData();
        await fetchWithAuthCheck(`/users/halykbank-payment-methods/`, {
            method: 'GET',
        })
            .then(async response => {
                    const jsonData = await response.json();
                    setPaymentData(jsonData);
                    return jsonData
                }
            )
    }, []);

    const createPaymentObject = function (auth, invoiceId, amount) {

        // создаем объект для платежа
        const paymentObject = {
            invoiceId: invoiceId,
            postLink: "https://stubla.com/en/api/v1/shops/receive-payment-message/",
            language: "RU",
            description: "Оплата в интернет магазине",
            accountId: "testuser1",
            terminal: "c12b3d9c-471a-4d95-9143-27a020bc6b59",
            client_secret: "gUK8tyb8%RedKX0f",
            amount: amount,
            currency: "KZT",
            phone: '',
            email: '',
            cardSave: true,  //Параметр должен передаваться как Boolean
        };

        paymentObject.auth = auth;
        return paymentObject;
    };

    const getPaymentTokenForWidget = async (invoiceID, price) => {
        // получаем токен для оплаты
        const data = new FormData();
        data.append("grant_type", "client_credentials");
        data.append("scope", "webapi usermanagement email_send verification statement statistics payment");
        data.append("client_id", "stubla.kz"); // prod
        // data.append("client_id", "test");
        data.append("client_secret", "gUK8tyb8%RedKX0f"); // prod
        // data.append("client_secret", "yF587AV9Ms94qN2QShFzVR3vFnWkhjbAK3sG");
        data.append("invoiceID", invoiceID);
        data.append("amount", price);
        data.append("currency", "KZT");
        data.append("terminal", "c12b3d9c-471a-4d95-9143-27a020bc6b59"); // prod
        // data.append("terminal", "67e34d63-102f-4bd1-898e-370781d0074d");

        // const response = await fetch(`https://testoauth.homebank.kz/epay2/oauth2/token`, {
        const response = await fetch(`https://epay-oauth.homebank.kz/oauth2/token`, {
            method: 'POST',
            headers: {
                Accept: 'application/form-data'
            },
            body: data
        });

        const jsonData = await response.json();
        console.log('token', jsonData)
        return jsonData;
    };

    const firstPaymentParams = {
        invoiceID: String(orderId).padStart(6, '0'), // prod
        amount: totalPrice,
    };

    const paymentIsSuccess = (obj) => {
        // после оплаты возвращает {success: true/false}
        console.log(obj);
        setPayment(obj);
        return obj;
    };

    return (
        <Layout>
            <Link to={"/Order"}
                  className="back-btn-cart"
                  state={{
                      id: orderId
                  }}>
                <div className="back_to_main_btn">Назад</div>
            </Link>
            <div className="Payment">
                <div className="Payment_cards-list">
                    {paymentSucceeded || payment.success === true
                        ? <div>Платеж прошел успешно</div>
                        : <div>
                            <h2>Выберите карту: </h2>
                            {JSON.stringify(paymentData) !== '[]'
                                ?
                                <div>
                                    {paymentData.map(item => {
                                        return <label className="Payment_card-list-item" key={item.id}>
                                            <input
                                                value={item.id}
                                                type="radio"
                                                onChange={() => setCard(item)}
                                            />
                                            {item.card_mask}
                                        </label>
                                    })}
                                    <button
                                        className="Payment-pay_btn button-yellow"
                                        onClick={() => fetchPaymentBySavedCard()}
                                    >Оплатить выбранной картой
                                    </button>
                                </div>
                                :
                                <div>
                                    <div style={{fontSize: '20px'}}>У вас нет сохраненных карт</div>
                                    <button className="Payment-pay_btn button-yellow"
                                            disabled={!localStorage.getItem('tel')}
                                            onClick={async () => {
                                                if (localStorage.getItem('tel')) {
                                                    // вызываем виджет оплаты
                                                    window.halyk.showPaymentWidget(createPaymentObject(await getPaymentTokenForWidget(firstPaymentParams.invoiceID, totalPrice), firstPaymentParams.invoiceID, totalPrice), paymentIsSuccess)
                                                }
                                            }}
                                    >Ввести данные карты
                                    </button>
                                </div>
                            }
                        </div>
                    }
                </div>
            </div>
        </Layout>
    );
};

export default Payment;