import {combineReducers} from "redux";
import {
    categoriesReducer,
    goodsBySubcategoryReducer,
    selectCategoryReducer,
    selectSubcategoryReducer,
    subcategoriesReducer
} from "./categoriesAndSubcategoriesReducer";
import {authReducer} from "./authReducer";
import {modalReducer} from "./modalReducer";
import {goodsReducer} from "./goodsReducer";
import {cartFromApiReducer} from "./cartFromApiReducer";
import {addressesReducer} from "./addressesReducer";

export const rootReducer = combineReducers({
    categoriesReducer,
    subcategoriesReducer,
    selectCategoryReducer,
    selectSubcategoryReducer,
    authReducer,
    modalReducer,
    goodsBySubcategoryReducer,
    goodsReducer,
    cartFromApiReducer,
    addressesReducer
});