import {GET_USER_ADDRESSES} from "../actions/actionTypes";

const initialState = {
    addresses: [],
}

export const addressesReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_USER_ADDRESSES :
            return {
                ...state,
                addresses: action.addresses
            }
        default:
            return state;
    }
};