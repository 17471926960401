export const FETCH_DEFAULT_CATEGORIES = 'FETCH_DEFAULT_CATEGORIES';
export const FETCH_DEFAULT_SUBCATEGORIES = 'FETCH_DEFAULT_SUBCATEGORIES';
export const FETCH_DEFAULT_GOODS = 'FETCH_DEFAULT_GOODS';
export const SELECT_CATEGORY = 'SELECT_CATEGORY';
export const SELECT_SUBCATEGORY = 'SELECT_SUBCATEGORY';
export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';
export const OPEN_AUTH_MODAL = 'OPEN_AUTH_MODAL';
export const CLOSE_AUTH_MODAL = 'CLOSE_AUTH_MODAL';
export const OPEN_REG_MODAL = 'OPEN_REG_MODAL';
export const CLOSE_REG_MODAL = 'CLOSE_REG_MODAL';
export const SELECT_SUBCATEGORIES_BY_CATEGORY = 'SELECT_SUBCATEGORIES_BY_CATEGORY';
export const FETCH_GOODS_BY_SUBCATEGORY = 'FETCH_GOODS_BY_SUBCATEGORY';
export const ADD_TO_CART = 'ADD_TO_CART';
export const SET_GOOD_COUNTER = 'SET_GOOD_COUNTER';
export const REMOVE_GOOD_FROM_CART = 'REMOVE_GOOD_FROM_CART';
export const SET_CART_FROM_API = 'SET_CART_FROM_API';
export const SET_ORDER_FROM_API = 'SET_ORDER_FROM_API';
export const GET_USER_ADDRESSES = 'GET_USER_ADDRESSES';

