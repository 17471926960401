import React from 'react';
import './Categories.css'
import CategoryItem from "../CategoryItem/CategoryItem";
import {useDispatch, useSelector} from "react-redux";
import {selectCategory, selectSubcategoriesByCategory} from "../../actions/actions";

const Categories = () => {

    const dispatch = useDispatch();

    const defaultCategories = useSelector(state => {
        const {categoriesReducer} = state;
        return categoriesReducer.defaultCategories;
    });

    const defaultSubcategories = useSelector(state => {
        const {subcategoriesReducer} = state;
        return subcategoriesReducer.defaultSubcategories;
    });

    const onSelectCategory = (category) => {
        const subcategoriesByCategory = defaultSubcategories.filter(subcategory => {
            if (subcategory?.parent_category_info?.id === category.id) {
                return subcategory
            }
        });

        dispatch(selectCategory(category));
        dispatch(selectSubcategoriesByCategory(subcategoriesByCategory));
    }; // выбираем нужную категрию и подменяем дефолтные подкатегории

    return (
        <div className='Categories'>
            {defaultCategories.map(category =>
                <CategoryItem
                    className="CategoryItem"
                    onSelectCategory={onSelectCategory}
                    category={category}
                    key={category.id}
                />
            )}
        </div>
    );
};

export default Categories;