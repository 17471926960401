import React, {useState} from 'react';
import './Auth.css';
import {useDispatch} from "react-redux";
import {closeAuthModal, login, openRegModal} from "../../actions/actions";
import {mainUrl} from '../../config';

const Auth = () => {

    const [tel, setTel] = useState('');
    const [password, setPassword] = useState('');
    const [correctPassword, setCorrectPassword] = useState(true);
    const [forgetPassword, setForgetPassword] = useState(false);
    const [userData, setUserData] = useState({ // локальный стейт данных юзера
        confirmation_code: "",
        password: "",
        password2: "",
        phone_number: "",
    });

    const dispatch = useDispatch();

    const fetchUserData = async (token) => { // получаем данные пользователя
        try {
            const response = await fetch(`${mainUrl}/users/?query=${localStorage.getItem('tel')}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            });
            const jsonData = await response.json();
            const result = jsonData.results;
            localStorage.setItem('user_name', result[0].name);
        } catch (e) {
            console.log(e)
        }
    };

    // логин
    const loginUser = async (tel, password) => {
        try {
            const response = await fetch(`${mainUrl}/login/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    phone_number: tel,
                    password: password
                })
            });

            const jsonData = await response.json();


            if (jsonData.detail !== "No active account found with the given credentials") {
                // проверяем ответ от сервера, если пароль верные выполняем:
                localStorage.setItem('access', jsonData.access);
                localStorage.setItem('refresh', jsonData.refresh);
                localStorage.setItem('tel', tel);
                setTel('');
                dispatch(login(tel, password));
            }

            // если ответ не верный то выводим сообщение об этом
            if (jsonData?.detail === 'No active account found with the given credentials') {
                setCorrectPassword(false);
            } else {
                await fetchUserData(jsonData.access);
                dispatch(closeAuthModal());
            }
        } catch
            (e) {
            console.log(e)
        }
    };

    const fetchConfirmationCode = async () => { // отправляем код подтверждения
        try {
            await fetch(`${mainUrl} / en / api / v1 / send - code / `, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({phone_number: userData.phone_number})
            });
        } catch (e) {
            console.log(e)
        }
    };

    const equalPasswords = () => { // сравниваем пароли
        return userData.password === userData.password2
    };

    const fetchNewPassword = async () => {
        try {
            await fetch(`${mainUrl}/en/api/v1/reset-password/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    phone_number: userData.phone_number,
                    new_password: userData.password,
                    new_password2: userData.password2,
                    confirmation_code: userData.confirmation_code,
                })
            });

            setForgetPassword(false);

            setUserData({
                ...userData,
                password: "",
                password2: "",
                confirmation_code: "",
            });
        } catch (e) {
            console.log(e)
        }
    };

    const [phoneNumber, setPhoneNumber] = useState('+7')

    return (
        <div className="Auth">
            {forgetPassword
                ? <div className="Auth_inner">
                    <div
                        className="back_to_main_btn auth_back_to_main_btn button-grey1"
                        onClick={() => dispatch(closeAuthModal())}
                    >Назад
                    </div>
                    <div className="Auth_inner-form">
                        <form className="Auth_form">
                            <input onChange={event => {
                                setUserData({
                                    ...userData,
                                    phone_number: event.target.value
                                })
                                setPhoneNumber(event.target.value)
                            }} className="Auth_form-tel"
                                   placeholder="Номер телефона" type="tel"
                                   value={phoneNumber}
                                   title="Введите номер телефона +7701XXXXXXX"/>
                            <p onClick={() => fetchConfirmationCode()}>Отправить код</p>
                            <input
                                type="text"
                                className="Register_confirmation-input"
                                onChange={event => {
                                    setUserData({...userData, confirmation_code: event.target.value})
                                }}
                                placeholder="Код подтверждения"
                            />
                            <input
                                className="Register_form-password"
                                onChange={event => {
                                    setUserData({...userData, password: event.target.value})
                                }}
                                placeholder="Пароль" type="text"
                                minLength="8"/>
                            <input
                                className="Register_form-password Register_form-password-repeat"
                                onChange={event => {
                                    setUserData({...userData, password2: event.target.value})
                                }}
                                placeholder="Повторите пароль" type="text"
                                minLength="8"/>
                            {equalPasswords() || <p style={{'color': 'red', fontSize: 12}}>Пароли не совпадают</p>}
                        </form>
                        <button
                            type="button"
                            onClick={() => fetchNewPassword()}
                        >Отправить
                        </button>
                    </div>
                </div>
                : <div className="Auth_inner">
                    <div
                        className="back_to_main_btn auth_back_to_main_btn"
                        onClick={() => dispatch(closeAuthModal())}
                    >Назад
                    </div>
                    <div className="Auth_inner-form">
                        <form className="Auth_form">
                            <input onChange={event => {setTel(event.target.value) 
                            setPhoneNumber(event.target.value)}} 
                                   className="Auth_form-tel"
                                   placeholder="Номер телефона" type="tel"
                                   value={phoneNumber}
                                   title="Введите номер телефона +7701XXXXXXX"/>
                            <input onChange={event => setPassword(event.target.value)}
                                   className="Auth_form-password"
                                   placeholder="Пароль" type="password"/>
                        </form>

                        {correctPassword // проверяем правильный ли пароль
                            ? null
                            : <p style={{'color': 'red', fontSize: 12}}>Неверный логин или пароль</p>}

                        <p
                            className="Auth_inner-forgotten"
                            onClick={() => {
                                setForgetPassword(true);
                            }}
                        >Забыли пароль?</p>
                        <div className="Auth_buttons">
                            <button type="submit" onClick={() => {
                                // логинимся
                                loginUser(tel, password);
                                // закрываем модалку с авторизацией
                            }} className="Auth_signin button-yellow">Войти
                            </button>
                            <div
                                className="Auth_register button-grey1"
                                // открываем модалку с регистрацией
                                onClick={() => dispatch(openRegModal())}
                            >Зарегистрироваться
                            </div>
                        </div>
                    </div>
                </div>
            }

        </div>
    );
};

export default Auth;