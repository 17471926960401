import {mainUrl} from "./config";

export const updateTokens = async () => { // запрос на обновление токенов
    try {
        const response = await fetch(`${mainUrl}/refresh-token/`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                "refresh": localStorage.getItem('refresh')
            })
        })

        if (response.status === 200) {
            const jsonData = await response.json();
            localStorage.setItem('access', jsonData.access);
            localStorage.setItem('refresh', jsonData.refresh);
            return {success: true, message: "Ok"};
        }
        return {success: false, message: response?.message}
    } catch (e) {
        console.log(e)
        return {success: false, message: e.message}
    }

};

export const fetchActiveOrders = async () => { // запрос на активные заказы
    try {
        const response = await fetch(`${mainUrl}/shops/orders/active/`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('access')}`,
                'Content-Type': 'application/json',
            }
        });

        const jsonData = await response.json();
        return jsonData;
    } catch (e) {
        console.log(e)
    }
};

export const fetchWithAuthCheck = async (url, options) => {

    let tokenData = null; // объявляем локальную переменную tokenData

    const requestUrl = mainUrl + url;

    if (localStorage.getItem('access')) { // если в localstorage присутствует access, то берем его
        tokenData = localStorage.getItem('access');
    }

    if (tokenData) { //проверяем есть ли токен
        if (!options.headers) { // если в запросе отсутствует headers, то задаем их
            options.headers = {
                'Authorization': `Bearer ${localStorage.getItem('access')}`,
                'Content-Type': 'application/json',
            };
        }

        const response = await fetch(requestUrl, options); // делаем запрос

        if (response.status === 401) {  // проверяем статус запроса
            // если unauthorized то обновляем токены
            const newTokensResponse = await updateTokens();
            if (newTokensResponse.success) { // про получениие токенов создаем заголовки и снова делаем запрос
                options.headers = {
                    'Authorization': `Bearer ${localStorage.getItem('access')}`,
                    'Content-Type': 'application/json',
                };
                const newResponse = await fetch(requestUrl, options);
                return newResponse; // при удачном запросе возвращаем ответ
            } else {
                return {status: -1, message: newTokensResponse.message}; // при неудачном запросе возвращаем объект
            }
        } else {
            return response;
        }
    }
}