import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import Layout from "../../containers/Layout/Layout";
import './Cart.css';
import CartItem from "../CartItem/CartItem";
import {Link} from "react-router-dom";
import {setCartFromApi} from "../../actions/actions";
import {mainUrl} from '../../config';
import {fetchWithAuthCheck} from "../../fetches";

const Cart = () => {

        const dispatch = useDispatch();

        const cartFromApi = useSelector(state => {
            const {cartFromApiReducer} = state;
            return cartFromApiReducer.cartFromApi;
        });

        // const getCartFromApi = async () => { // запрос на корзину
        //     try {
        //         const response = await fetch(`${mainUrl}/shops/shopping-cart/`, {
        //             method: 'GET',
        //             headers: {
        //                 'Authorization': `Bearer ${localStorage.getItem('access')}`,
        //                 'Content-Type': 'application/json',
        //             }
        //         });
        //
        //         const jsonData = await response.json();
        //         await dispatch(setCartFromApi(jsonData));
        //     } catch (e) {
        //         console.log(e)
        //     }
        // };

        useEffect(async () => {
            await fetchWithAuthCheck('/shops/shopping-cart/', {
                method: 'GET',
            })
                .then(async response => {
                        const jsonData = await response.json();
                        dispatch(setCartFromApi(jsonData));
                    }
                )
        }, [])

        return (
            <Layout>
                <Link to={"/"} className="back-btn-cart">
                    <div className="back_to_main_btn">Назад</div>
                </Link>
                <h2 className="Cart_header">Корзина:</h2>
                {JSON.stringify(cartFromApi) !== '[]'
                    // проверка если корзина пустая
                    ? <div className="Cart">
                        {cartFromApi.map(shop => {
                            return (
                                <CartItem shop={shop} key={shop.id} id={shop.id}/>
                            )
                        })}
                    </div>
                    :   // выводим если корзина пустая
                    <div style={{textAlign: 'center'}}>Корзина пуста</div>
                }
            </Layout>
        );
    }
;

export default Cart;