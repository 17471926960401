import './App.css';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import Main from "../containers/Main";
import GoodDetail from "../components/GoodDetail/GoodDetail";
import Cart from "../components/Cart/Cart";
import Order from "../components/Order/Order";
import React from "react";
import Payment from "../components/Payment/Payment";
import Profile from "../components/Profile/Profile";
import ActiveOrders from "../components/ActiveOrders/ActiveOrders";
import ActiveOrderItemDetail from "../components/ActiveOrderItemDetail/ActiveOrderItemDetail";

function App() {

    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Main/>}/>
                <Route path="/goodDetail" element={<GoodDetail/>}/>
                <Route path="/cart" element={<Cart/>}/>
                <Route path="/order" element={<Order/>}/>
                <Route path="/payment" element={<Payment/>}/>
                <Route path="/activeOrders" element={<ActiveOrders/>}/>
                <Route path="/activeOrderItemDetail" element={<ActiveOrderItemDetail/>}/>
                <Route path="/profile" element={<Profile/>}/>
                <Route path="*" render={() => <h1>404 Not Found</h1>}/>
            </Routes>
        </BrowserRouter>
    );
}

export default App;