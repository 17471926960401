import React from 'react';
import './Goods.css';
import {useSelector} from "react-redux";
import Good from "../../components/Good/Good";

const Goods = () => {
    const goodsBySubcategory = useSelector(state => {
        const {goodsBySubcategoryReducer} = state;
        return goodsBySubcategoryReducer.goodsBySubcategory;
    });

    return (
        // выводим список подкатегорий
        <div className='Goods'>
            {goodsBySubcategory.map(good =>
                <Good
                    name={good.name}
                    key={good.id}
                    id={good.id}
                    image={good?.categories[0]?.image}
                    price={good?.prices?.max}
                    good={good}
                />
            )}
        </div>
    );

};

export default Goods;