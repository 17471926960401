import {
    ADD_TO_CART, REMOVE_GOOD_FROM_CART, SET_GOOD_COUNTER,
} from "../actions/actionTypes";

const initialState = {
    cart: []
}

export const goodsReducer = (state = initialState, action) => {
        switch (action.type) {
            case ADD_TO_CART : {
                const cartFromState = [...state.cart];
                let flag = false;
                const checkedCart = cartFromState.map(good => {

                    if (good.id === action.good.id) {
                        flag = true; // проверяем повторился ли товар
                        return {
                            ...good,
                            goodCount: good.goodCount + action.counter
                        }
                    } else {
                        return {...good}
                    }
                })

                if (!flag) {
                    checkedCart.push({
                        ...action.good,
                        goodCount: action.counter
                    }) // если такого товара нет то добавляем в корзину
                }
                return {
                    ...state,
                    cart: checkedCart
                }
            }
            case SET_GOOD_COUNTER : {

                const cartFromState = [...state.cart];

                const checkedCart = cartFromState.map(good => {
                    if (good.id === action.good.id) {
                        return {
                            ...good,
                            goodCount: action.good?.goodCount
                        }
                    }
                })
                return {
                    ...state,
                    cart: checkedCart
                }
            }
            case REMOVE_GOOD_FROM_CART : {
                const cartFromState = [...state.cart];
                let index = cartFromState.map(el => el.id).indexOf(action.id);
                cartFromState.splice(index, 1)

                return {
                    ...state,
                    cart: cartFromState
                }
            }
            default:
                return state;
        }
    }
;