import React, {useEffect, useState} from 'react';
import Layout from "../../containers/Layout/Layout";
import {useNavigate} from "react-router";
import {fetchActiveOrders} from "../../fetches";
import './ActiveOrders.css';
import ActiveOrderItem from "../ActiveOrderItem/ActiveOrderItem";
import {useSelector} from "react-redux";

const ActiveOrders = () => {

    const navigate = useNavigate();

    const [orders, setOrders] = useState([]);

    console.log(orders)

    const isAuth = useSelector(state => {
        const {authReducer} = state;
        return authReducer.isAuth;
    });

    useEffect(async () => {
        const activeOrders = await fetchActiveOrders();
        setOrders(activeOrders);
    }, orders)

    return (
        <Layout>
            <button onClick={() => navigate(-1)}
                    className="back-btn-profile button-grey1">
                Назад
            </button>
            <div className="OrdersStatus">
                <h2>Активные заказы:</h2>
                {JSON.stringify(orders) !== '[]'
                    ? orders.map(order => {
                        return (<ActiveOrderItem
                            key={order.id}
                            order={order}
                        />)
                    })
                    : <div>У вас нет активных заказов</div>
                }
            </div>
        </Layout>
    );
};

export default ActiveOrders;