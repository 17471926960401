import React from 'react';
import "./SubcategoryItem.css";

const SubcategoryItem = ({id, subcategory, onSelectSubcategory}) => {

    let imageSrcHTTP = subcategory.image
    let imageSrcHTTPS = imageSrcHTTP.replace('http', 'https')

    return (
        <div
            className="SubcategoryItem"
            key={id}
            onClick={() => {onSelectSubcategory(subcategory)
            console.log(subcategory)}}
        >
            <img className="SubcategoryItem_pic" src={imageSrcHTTPS} alt={subcategory.name}/>
            <div className="SubcategoryItem_name">{subcategory.name}</div>
        </div>
    );
};

export default SubcategoryItem;