import React, {useEffect, useState} from "react";
import './Layout.css';
import notification_img from '../../assets/images/Notification_Icon.png';
import logo from '../../assets/images/stubla_no_borders_logo.png';
import {useDispatch, useSelector} from "react-redux";
import {logout, openAuthModal, openRegModal} from "../../actions/actions";
import Modal from "../Modal/Modal";
import Register from "../../components/Register/Register";
import Auth from "../../components/Auth/Auth";
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faBagShopping} from "@fortawesome/free-solid-svg-icons";
import {mainUrl} from '../../config';

function Layout({children}) {

    const modalStatus = useSelector(state => { // статус модального окна
        const {modalReducer} = state;
        return modalReducer;
    });

    const user = useSelector(state => {
        const {authReducer} = state;
        return authReducer.isAuth;
    });

    const dispatch = useDispatch();

    const logoutUser = async () => { // выход
        try {
            // отправляем рефреш токен в черный список при выходе из акаунта

            const fetchLogout = await fetch(`${mainUrl}/blacklist/`,
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        refresh_token: localStorage.getItem('refresh')
                    })
                });
            if (fetchLogout.status === 205) {
                localStorage.removeItem('tel');
                localStorage.removeItem('user_name');
                localStorage.removeItem('appState');
                dispatch(logout());
            }

        } catch (e) {
            console.log(e)
        }
    };

    return (
        <div className='Layout'>
            <header className="Layout_header">
                <div className="Layout_notofication">
                    <img className="Layout_notofication_img" src={notification_img} alt="notifiations"/>
                    <div className="Layout_notofication_marker" style={{display: 'none'}}>1</div>
                </div>
                <div className="Layout_logo">
                    <img className="Layout_logo_img" src={logo} alt="logo"/>
                </div>

                <div className="Layout_auth_controls">
                    {user ? // проверка юзера на авторизацию
                        // выводим если авторизован
                        <div className="Layout_user_login">
                            <Link className="Layout_user-cart_btn"
                                  to={'/cart'}>
                                {/*<div>Корзина</div>*/}
                                <FontAwesomeIcon className="Layout_user-cart_btn_icon" icon={faBagShopping} size="2x"/>
                            </Link>
                            <Link
                                className="Layout_user_login-name"
                                to={'/profile'}
                            >{localStorage.getItem('user_name')}</Link>
                            <button className="Layout_user_login-logout_btn button-yellow"
                                    onClick={() => logoutUser()}>Выйти
                            </button>
                        </div>
                        :
                        // выводим кнопкии войти и зарегистрироваться если юзер не авторизован
                        <div className="Layout_login-register-buttons">
                            <button
                                className="Layout_log-in button-yellow"
                                onClick={() => {
                                    dispatch(openAuthModal()) //  открытие модалки на аторизацию
                                }
                                }>Log In
                            </button>
                            <button
                                className="Layout_register button-grey1"
                                onClick={() => {
                                    dispatch(openRegModal()) // открытие модалки на регистрацию
                                }}
                            >Register
                            </button>
                        </div>}
                </div>
            </header>
            {/*менюшка*/}
            <nav className="Layout_nav">
                <ol className="Layout_nav_list">
                    <li className="Layout_nav_list_item">
                        <a className="Layout_nav_list_item_link" href="#">Главная</a>
                    </li>
                    <li className="Layout_nav_list_item">
                        <a className="Layout_nav_list_item_link" href="#">Сообщения</a>
                    </li>
                    <li className="Layout_nav_list_item">
                        <a className="Layout_nav_list_item_link" href="#">Приложение</a>
                    </li>
                    {user
                        ? <li className="Layout_nav_list_item">
                            <Link
                                to="/ActiveOrders"
                                className="Layout_nav_list_item_link"
                            >Заказы</Link>
                        </li>
                        : null
                    }
                </ol>
            </nav>
            {modalStatus.authModal // вывод модалки с авторизацией
                ? <Modal><Auth/></Modal>
                : null}
            {modalStatus.regModal // вывод модалки с регистрацией
                ? <Modal><Register/></Modal>
                : null}

            {children}
        </div>
    )
}

export default React.memo(Layout);