import React, {useEffect, useRef, useState} from 'react';
import {setCartFromApi, setOrderFromApi} from "../../actions/actions";
import {Link, useLocation} from "react-router-dom";
import Layout from "../../containers/Layout/Layout";
import {useDispatch, useSelector} from "react-redux";
import './Order.css';
import Map, {Marker} from 'react-map-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import {useNavigate} from "react-router";
import {mainUrl} from '../../config';
import {fetchWithAuthCheck} from "../../fetches";

const Order = () => {
        const [payment, setPayment] = useState({});
        const [selfPickup, setSelfPickup] = useState(true);
        const [userAddresses, setUserAddresses] = useState([]);
        const [selectedAddress, setSelectedAddress] = useState('');
        const [showMap, setShowMap] = useState(false);
        const [defaultLatitude, setDefaultLatitude] = useState(43.237376);
        const [defaultLongitude, setDefaultLongitude] = useState(76.8638976);
        const [newLatitude, setNewLatitude] = useState('');
        const [newLongitude, setNewLongitude] = useState('');
        const [zoom, setZoom] = useState(15);
        const [addressFromInput, setAddressFromInput] = useState('');
        const [addressIsAdded, setAddressIsAdded] = useState(false);

        const dispatch = useDispatch();

        const location = useLocation();
        const navigate = useNavigate();

        const orderId = location.state.id;

        const order = useSelector(state => {
            const {cartFromApiReducer} = state;
            return cartFromApiReducer.orderFromApi;
        });

        const isAuth = useSelector(state => {
            const {authReducer} = state;
            return authReducer.isAuth;
        });

        // const removeGood = async (unitId) => { // запрос на удаление товара из заказа
        //     try {
        //         const response = await fetch(`${mainUrl}/shops/order-units/`, {
        //             method: 'DELETE',
        //             headers: {
        //                 'Authorization': `Bearer ${localStorage.getItem('access')}`,
        //                 'Content-Type': 'application/json',
        //             },
        //             body: JSON.stringify({
        //                 "order_unit_id": unitId
        //             })
        //         });
        //
        //         if (response.status === 204) {
        //             dispatch(setOrderFromApi(fetchOrderFromApi(orderId)))
        //         }
        //
        //     } catch (e) {
        //         console.log(e)
        //     }
        // };

        // const fetchRemoveOrder = async (orderId) => { // удаление всего заказа
        //     try {
        //         const response = await fetch(`${mainUrl}/shops/orders/${orderId}`, {
        //             method: 'DELETE',
        //             headers: {
        //                 'Authorization': `Bearer ${localStorage.getItem('access')}`,
        //                 'Content-Type': 'application/json',
        //             },
        //         });
        //
        //         if (response.status === 204) {
        //             navigate('/Cart');
        //             dispatch(setOrderFromApi({}))
        //         } else {
        //             await fetchOrderFromApi(orderId);
        //         }
        //
        //     } catch (e) {
        //         console.log(e)
        //     }
        // };

        // const fetchOrderFromApi = async (id) => { // запрос на информацию по заказу
        //     try {
        //         const response = await fetch(`${mainUrl}/shops/orders/${id}/`, {
        //             method: 'GET',
        //             headers: {
        //                 'Authorization': `Bearer ${localStorage.getItem('access')}`,
        //                 'Content-Type': 'application/json',
        //             },
        //         });
        //         const jsonData = await response.json();
        //         dispatch(setOrderFromApi(jsonData));
        //         return jsonData;
        //     } catch (e) {
        //         console.log(e)
        //     }
        // };

        // const fetchUserAddress = async () => { // запрос на адреса пользователя
        //     try {
        //         const response = await fetch(`${mainUrl}/users/addresses/`, {
        //             method: 'GET',
        //             headers: {
        //                 'Authorization': `Bearer ${localStorage.getItem('access')}`,
        //                 'Content-Type': 'application/json',
        //             },
        //         });
        //         const jsonData = await response.json();
        //         await setUserAddresses(jsonData);
        //         // dispatch(getUserAddresses(jsonData));
        //     } catch (e) {
        //         console.log(e)
        //     }
        // };

        // const fetchSelfPickup = async (id, pickup) => { // запрос на самовывоз
        //     let address = null;
        //
        //     if (!pickup) {
        //         address = userAddresses[0]?.id;
        //     }
        //
        //     try {
        //         const response = await fetch(`${mainUrl}/shops/orders/${id}/`, {
        //             method: 'PATCH',
        //             headers: {
        //                 'Authorization': `Bearer ${localStorage.getItem('access')}`,
        //                 'Content-Type': 'application/json',
        //             },
        //             body: JSON.stringify({
        //                 "self_pickup": pickup,
        //                 "address_id": address
        //             })
        //         });
        //         const jsonData = await response.json();
        //         if (response.status === 200) {
        //             setSelfPickup(!selfPickup);
        //         }
        //     } catch (e) {
        //         console.log(e)
        //     }
        // };

        // const selectAddress = async (id) => { //установить выбранный адрес
        //     try {
        //         const response = await fetch(`${mainUrl}/users/addresses/${id}/`, {
        //             method: 'PATCH',
        //             headers: {
        //                 'Authorization': `Bearer ${localStorage.getItem('access')}`,
        //                 'Content-Type': 'application/json',
        //             },
        //             body: JSON.stringify(
        //                 {
        //                     "is_default": true
        //                 }
        //             )
        //         });
        //         const jsonData = await response.json();
        //         console.log(jsonData)
        //     } catch (e) {
        //         console.log(e)
        //     }
        // };

        // const fetchAddressByLocation = async () => { // получаем адрес по локации
        //     try {
        //         const response = await fetch(`${mainUrl}/users/get-address-by-location/?lat=${newLatitude}&lng=${newLongitude}`, {
        //             method: 'GET',
        //             headers: {
        //                 'Authorization': `Bearer ${localStorage.getItem('access')}`,
        //                 'Content-Type': 'application/json',
        //             }
        //         });
        //         const jsonData = await response.json();
        //         setSelectedAddress(jsonData.address);
        //
        //         return jsonData.address;
        //     } catch (e) {
        //         console.log(e)
        //     }
        // };

        // const fetchNewAddress = async () => { // отправить новый адрес
        //     try {
        //         const response = await fetch(`${mainUrl}/users/addresses/`, {
        //             method: 'POST',
        //             headers: {
        //                 'Authorization': `Bearer ${localStorage.getItem('access')}`,
        //                 'Content-Type': 'application/json',
        //             },
        //             body: JSON.stringify(
        //                 {
        //                     "city_id": 1,
        //                     "address_string": selectedAddress,
        //                     "detail_info": addressFromInput,
        //                     "lat": newLatitude,
        //                     "lng": newLongitude,
        //                     "is_default": true
        //                 }
        //             )
        //         });
        //         const jsonData = await response.json();
        //         if (response.status === 201) {
        //             setAddressIsAdded(true);
        //             setAddressFromInput('');
        //             setTimeout(() => setAddressIsAdded(false), 1500);
        //         }
        //
        //     } catch (e) {
        //         console.log(e)
        //     }
        // };

        const selectAddressFromInput = (e) => {
            setAddressFromInput(e.target.value)
        };

        function getUserLocation(position) { // узнаем геолокацию пользователя при загрузке страницы
            const coordinates = position.coords;

            setDefaultLongitude(coordinates.longitude);
            setDefaultLatitude(coordinates.latitude);
        };


        useEffect(async () => {
            // fetchOrderFromApi(orderId); // загружаем заказ при рендере
            await fetchWithAuthCheck(`/shops/orders/${orderId}/`, {
                method: 'GET',
            })
                .then(async response => {
                        const jsonData = await response.json();
                        dispatch(setOrderFromApi(jsonData));
                        return jsonData;
                    }
                );

            // await fetchUserAddress();
            await fetchWithAuthCheck(`/users/addresses/`, {
                method: 'GET',
            })
                .then(async response => {
                        const jsonData = await response.json();
                        await setUserAddresses(jsonData);
                    }
                );

            navigator.geolocation.getCurrentPosition(getUserLocation);
        }, []);

        return (
            <Layout>
                <Link to={"/cart"} className="back-btn-cart">
                    <div className="back_to_main_btn">Назад</div>
                </Link>
                {payment.success
                    ? <h3 style={{textAlign: 'center'}}>Платеж прошел успешно!</h3>
                    : <div className="Order">
                        <div className="Order_shop-name">{order.shop?.name}</div>
                        <div className="Order_id">Номер заказа: {orderId}</div>
                        <div className="Order_delivery">
                            <div>Веберите способ получения товара:</div>
                            <button
                                className={selfPickup
                                    ? "Order_delivery-self_pickup active"
                                    : "Order_delivery-self_pickup button-grey1"
                                }
                                onClick={
                                    // () => fetchSelfPickup(orderId, true)
                                    async () => {
                                        await fetchWithAuthCheck(`/shops/orders/${orderId}/`, {
                                            method: 'PATCH',
                                            body: JSON.stringify({
                                                self_pickup: true,
                                                address_id: userAddresses[0]?.id
                                            })
                                        })
                                            .then(async response => {
                                                    const jsonData = await response.json();
                                                    if (response.status === 200) {
                                                        setSelfPickup(!selfPickup);
                                                    }
                                                }
                                            )
                                    }
                                }
                            >Самовывоз
                            </button>
                            <button
                                className={selfPickup
                                    ? "Order_delivery-courier button-grey1"
                                    : "Order_delivery-courier active"
                                }
                                onClick={
                                    // () => fetchSelfPickup(orderId, false)
                                    async () => {
                                        await fetchWithAuthCheck(`/shops/orders/${orderId}/`, {
                                            method: 'PATCH',
                                            body: JSON.stringify({
                                                self_pickup: false,
                                                address_id: userAddresses[0]?.id
                                            })
                                        })
                                            .then(async response => {
                                                    const jsonData = await response.json();
                                                    if (response.status === 200) {
                                                        setSelfPickup(!selfPickup);
                                                    }
                                                }
                                            )
                                    }
                                }
                            >Доставка
                            </button>
                        </div>
                        {selfPickup
                            ? <div className="Order_shop-address">Адрес магазина: {order.shop?.address}</div>
                            : <div className="Order-customer_address-list">
                                Адрес доставки:
                                {userAddresses.map(address => {
                                    return (
                                        <label className="Order-customer_address-list-item"
                                               key={address.id}>
                                            <input name="address"
                                                   type="radio"
                                                   onChange={
                                                       // () => selectAddress(address.id)
                                                       async () => fetchWithAuthCheck(`/users/addresses/${address.id}/`, {
                                                           method: 'PATCH',
                                                           body: JSON.stringify({
                                                               is_default: true
                                                           })
                                                       })
                                                           .then(async response => {
                                                                   const jsonData = await response.json();
                                                               }
                                                           )
                                                   }
                                                   value={address.address_string}
                                            />
                                            {address.address_string}
                                        </label>
                                    )
                                })
                                }
                                <button
                                    className="Order-customer_add-address"
                                    onClick={() => setShowMap(!showMap)}
                                >
                                    Добавить новый адрес
                                </button>
                                {showMap
                                    ?
                                    <div>
                                        <Map
                                            onMouseMove={(e) => {
                                                console.log(e)
                                                setNewLatitude(e.lngLat.lat);
                                                setNewLongitude(e.lngLat.lng);

                                                // setZoom(map.current.getZoom().toFixed(2));
                                            }}
                                            onClick={
                                                // () => fetchAddressByLocation()
                                                () => fetchWithAuthCheck(`/users/get-address-by-location/?lat=${newLatitude}&lng=${newLongitude}`, {
                                                    method: 'GET',
                                                })
                                                    .then(async response => {
                                                        const jsonData = await response.json();
                                                        setSelectedAddress(jsonData.address);
                                                        return jsonData.address;
                                                    })
                                            }
                                            initialViewState={{
                                                longitude: defaultLongitude,
                                                latitude: defaultLatitude,
                                                zoom: zoom
                                            }}
                                            mapboxAccessToken={'pk.eyJ1IjoibWVsYW55Nzc3IiwiYSI6ImNsM2pvdjhlcTA0cTQzcHA2OTVjcnd0amEifQ.qapnEHWqZ89sf3MX8sFJ_Q'}
                                            style={{width: 600, height: 400}}
                                            mapStyle="mapbox://styles/mapbox/streets-v9"
                                        >
                                            <Marker
                                                longitude={defaultLongitude}
                                                latitude={defaultLatitude}
                                                anchor="bottom">
                                                <div>YOU</div>
                                            </Marker>
                                        </Map>
                                        <div className="Order-customer_address">
                                            <label className="Order-customer_address-label">
                                                Адрес: </label>
                                            <textarea
                                                onChange={(e) => selectAddressFromInput(e)}
                                                className="Order-customer_address-input"
                                                type="text"
                                                cols="50"
                                                rows="2"
                                                placeholder="Укажите номер дома и квартиры"
                                            />
                                        </div>
                                        {addressIsAdded && <div style={{color: '#ababab', margin: '15px'}}>Адрес
                                            добавлен</div>}
                                        <button
                                            onClick={
                                                // () => fetchNewAddress()
                                                async () => fetchWithAuthCheck(`/users/addresses/`, {
                                                    method: 'POST',
                                                    body: JSON.stringify({
                                                        city_id: 1,
                                                        address_string: selectedAddress,
                                                        detail_info: addressFromInput,
                                                        lat: newLatitude,
                                                        lng: newLongitude,
                                                        is_default: true
                                                    })
                                                })
                                                    .then(async response => {
                                                            const jsonData = await response.json();
                                                            if (response.status === 201) {
                                                                setAddressIsAdded(true);
                                                                setAddressFromInput('');
                                                                setTimeout(() => setAddressIsAdded(false), 1500);
                                                            }
                                                        }
                                                    )
                                            }
                                            className="Order-customer_add-new-address"
                                            disabled={!newLatitude || !newLongitude || !selectedAddress || !addressFromInput}
                                        >Добавить адрес
                                        </button>
                                    </div>
                                    : null
                                }
                            </div>
                        }
                        <div className="Order_good" key={order.id}>
                            <div className="Order_products-list">
                                {order.order_units?.map(product => {
                                    return (
                                        <div className="Order_productItem" key={product.id}>
                                            <div className="Order_productItem-product">
                                                <div className="Order-product">{product.unit.name}</div>
                                                {JSON.stringify(product?.unit?.verbose_properties) !== '[]'
                                                    ? <div className="Order-property">
                                                        <span>{product?.unit?.verbose_properties[0].name}</span>: <span>{product?.unit?.verbose_properties[0].value}</span>
                                                    </div>
                                                    : null
                                                }
                                                <div className="Order_count">Количество: {product.quantity}
                                                    <button className="Order_product-remove-btn button-yellow"
                                                            onClick={
                                                                // () => removeGood(product.id)
                                                                () => fetchWithAuthCheck('/shops/order-units/', {
                                                                    method: 'DELETE',
                                                                    body: JSON.stringify({
                                                                        order_unit_id: product.id
                                                                    })
                                                                })
                                                                    .then(async response => {
                                                                            if (response.status === 204) {
                                                                                dispatch(setOrderFromApi(
                                                                                        // fetchOrderFromApi(orderId)


                                                                                        await fetchWithAuthCheck(` / shops / orders /${orderId}/`, {
                                                                                            method: 'GET',
                                                                                        })
                                                                                            .then(async response => {
                                                                                                    const jsonData = await response.json();
                                                                                                    dispatch(setOrderFromApi(jsonData));
                                                                                                    return jsonData;
                                                                                                }
                                                                                            )
                                                                                    )
                                                                                )
                                                                            }
                                                                        }
                                                                    )
                                                            }
                                                    >Удалить
                                                    </button>
                                                </div>
                                            </div>
                                            <div
                                                className="Order_unit-total-price">
                                                <div>{product.quantity * product.unit.price}</div>
                                                <div>&#8376;</div>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                        <div className="Order-pay-controls">
                            <div className="Order_total-good-price">
                                <div style={{fontWeight: "bold"}}>Total price:</div>
                                <div className="Order_total-good-price-total">
                                    <div>{order.order_total_price}</div>
                                    <div>&#8376;</div>
                                </div>
                            </div>
                            {isAuth // проверяем залогинен ли пользователь для оплаты
                                ? null
                                : <div className="Order_warning">Войдите чтобы оплатить</div>}
                            <button className="Order-pay_remove-order-btn button-grey1"
                                    onClick={
                                        // () => fetchRemoveOrder(order.id)
                                        () => fetchWithAuthCheck(`/shops/orders/${orderId}`, {
                                            method: 'DELETE',
                                        })
                                            .then(async response => {
                                                    if (response.status === 204) {
                                                        navigate('/Cart');
                                                        dispatch(setOrderFromApi({}))
                                                    } else {
                                                        // await fetchOrderFromApi(orderId);
                                                        await fetchWithAuthCheck(`/shops/orders/${orderId}/`, {
                                                            method: 'GET',
                                                        })
                                                            .then(async response => {
                                                                    const jsonData = await response.json();
                                                                    dispatch(setOrderFromApi(jsonData));
                                                                    return jsonData;
                                                                }
                                                            )
                                                    }
                                                }
                                            )
                                    }
                            > Удалить заказ
                            </button>
                            <Link to={'/payment'}
                                  state={{
                                      id: orderId,
                                      price: order.order_total_price,
                                      selfPickup,
                                      selectedAddress,
                                      userAddresses
                                  }}
                                  className="Order-pay_btn button-yellow"
                            >Оплатить
                            </Link>
                        </div>
                    </div>
                }
            </Layout>
        )
            ;
    }
;

export default Order;