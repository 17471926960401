export const getStatusOfOrder = (num) => {
    switch (num) {
        case 0: {
            return 'Заказ оплачен';
        }
            break;
        case 1: {
            return 'Заказ принят';
        }
            break;
        case 2: {
            return 'Заказ ждет курьера';
        }
            break;
        case 3: {
            return 'Доставка';
        }
            break;
        case 4: {
            return 'Доставлено';
        }
            break;
        case 5: {
            return 'Заказ в архиве';
        }
            break;
        case 6: {
            return 'Заказ отклонен пользователемт';
        }
            break;
        case 7: {
            return 'Заказ отклонен магазином';
        }
            break;
        case 8: {
            return 'Просрочено';
        }
            break;
        default:
            break;
    }
};