import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App/App';
import {applyMiddleware, compose, createStore} from "redux";
import thunk from "redux-thunk";
import {Provider} from "react-redux";
import {rootReducer} from "./reducers/rootReducer";
import {composeWithDevTools} from 'redux-devtools-extension/developmentOnly';

const loadState = () => { // загружаем стейт из localStorage для инициализации
    try {
        const serialisedState = window.localStorage.getItem('appState');
        if (!serialisedState) return undefined;
        return JSON.parse(serialisedState);
    } catch (err) {
        return undefined;
    }
};

const oldState = loadState();

const store = createStore(rootReducer,
    oldState, // инициализируем стейт из localStorage
    composeWithDevTools(applyMiddleware(thunk)
    ));


const saveState = (state) => { // сохраняем стейт в localStorage
    try {
        const serialisedState = JSON.stringify(state);
        window.localStorage.setItem('appState', serialisedState);
    } catch (err) {
        // Log errors here, or ignore
    }
};

store.subscribe(() => {
    saveState(store.getState()); // сохраняем стейт в localStorage при изменении стейта
});

ReactDOM.render(
    <React.StrictMode>
        <Provider store={store}>
            <App/>
        </Provider>
    </React.StrictMode>,
    document.getElementById('root')
);
