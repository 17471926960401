import React from 'react';
import {Link} from "react-router-dom";
import './Good.css';
import {mediaUrl} from "../../config";

const Good = (props) => {
    return (
        <div className="Good"
             key={props.id}>
            <Link className="GoodsItem_Link"
                  to="/GoodDetail"
                  state={{
                      good: props.good
                  }}>
                <div className="GoodsItem">
                    <img className="GoodsItem_pic"
                         src={`${mediaUrl}${props?.good?.units[0]?.images[0]?.image}`}
                         alt={props.name}/>
                    {/*<img className="GoodsItem_pic"*/}
                    {/*     src={props.categories[0]?.image}*/}
                    {/*     alt={props.name}/>*/}
                    <div className="GoodsItem_name">{props.name}</div>
                    <div className="GoodsItem_price">{props.price} &#8376;</div>
                </div>
            </Link>
        </div>
    );
};

export default Good;