import React, {useEffect, useState} from 'react';
import Layout from "../../containers/Layout/Layout";
import {useLocation} from "react-router-dom";
import "./GoodDetail.css";
import {useSelector} from "react-redux";
import {useNavigate} from "react-router";
import {fetchWithAuthCheck} from "../../fetches";
import {mediaUrl} from "../../config";

const GoodDetail = () => {
    const location = useLocation();

    let good = location.state?.good;
    let [addingGood, setAddingGood] = useState(false);
    let [propertyId, setPropertyId] = useState(''); // id опции к товару
    let [mainId, setMainId] = useState(''); // главный ID товара
    let [imagesMassive, setImagesMassive] = useState()

    useEffect(() => {
        if (good?.units[0]?.verbose_properties !== '[]') {
            setMainId(good.units[0]?.id);
        }
        setImagesMassive(good?.units[0].images)

    }, [])

    const navigate = useNavigate();

    const goodsUnits = location?.state?.good?.units; // у каждого товара юниты с опциями

    const isUserAuth = useSelector(state => {
        const {authReducer} = state;
        return authReducer.isAuth;
    });

    const selectProperty = (id) => { // выбор опции к товару
        // setMainId('');
        setPropertyId(id);
    };

    const [modalActive, setModalActive] = useState()

    const openModal = (value) => {
        setModalActive(true)
        console.log(imagesMassive)
    }

    const closeModal = () => {
        setModalActive(false)
    }

    let imagesUrl =  `${mediaUrl}${good?.units[0]?.images[0]}`
    // setImagesMassive(imagesUrl)

    // let imagesDB = () => {
    // imagesDB = imagesUrl
    // console.log(imagesUrl)
    // }

    // {imagesUrl.map(item => {
    //     return (
    //        setImagesMassive(item)
    //     )
    // } )}

    // console.log("IIIIIIIIIIIIIIIIIMAGES MASSIVE", imagesMassive)

    const [mainModalImage, setMainModalImage] = useState(`${mediaUrl}${good?.units[0]?.images[0]?.image}`)

    return (<
            Layout>
            {modalActive? <><div className='modal'>
            <div className='modalMain'>
                <span onClick={closeModal}  className='close'>X</span>
                <img src={mainModalImage} className='modal-content' id='img01'/>
            </div>
            {JSON.stringify(imagesMassive) 
            ?   <div className='InModalImagesWrapper'>
                    {imagesMassive.map(item => {
                        return (
                            <div className='inModalImages'>
                                <img onClick={() => setMainModalImage(`${mediaUrl}${item.image}`)} src={`${mediaUrl}${item.image}`} />
                            </div>
                            
                        )
                    })}
                </div>
            :
            ''
            }
            </div></> : '' }
            <div className="GoodDetail">
                <button
                    onClick={() => navigate(-1)}
                    className="back-btn-profile">
                    Назад
                </button>
                <div className="GoodDetail_item">
                    <img className="GoodDetail_item_pic"
                         src={`${mediaUrl}${good?.units[0]?.images[0]?.image}`}
                         alt={good?.name}
                         onClick={openModal}/>
                    <div className="GoodDetail_item_name">{good?.name}</div>
                    <div className="GoodDetail_item_price">{good?.units[0].price}
                        <span className="GoodDetail_item_price_icon"> &#8376;</span>
                    </div>
                    <div className="GoodDetail_item_description">Описание
                        товара: <span>{good?.description}</span>
                    </div>
                    {JSON.stringify(goodsUnits[0]?.verbose_properties) !== '[]'
                        // проверяем есть ли verbose у товара
                        ? <div className="GoodDetail_verbose-items-list">
                            {goodsUnits.map(item => {
                                return (item?.verbose_properties.map(prop => {
                                    return (
                                        <label className="GoodDetail_verbose-item"
                                               key={prop.id}>
                                            <input name="unit_properties"
                                                   type="radio"
                                                   onChange={
                                                       () => selectProperty(item.id)
                                                   }
                                                   value={prop.id}/>
                                            {prop.name}: {prop.value}</label>
                                    )
                                }))
                            })
                            }
                            {addingGood &&
                                <div style={{color: '#ababab', textAlign: 'center', margin: '15px'}}>Товар
                                    добавлен</div>}
                            {!isUserAuth ?
                                <div style={{color: '#ababab', textAlign: 'center', margin: '15px'}}>Войдите на
                                    сайт,
                                    чтобы добавить товар</div> : null}
                            {!propertyId ?
                                <div style={{color: '#ababab', textAlign: 'center', margin: '15px'}}>Выберите
                                    товар</div> : null}
                            <button
                                className="GoodItem_add-to-cart goodDetail_addToCartBtn button-yellow"
                                onClick={
                                    () => fetchWithAuthCheck('/shops/add-to-cart/', {
                                        method: 'POST',
                                        body: JSON.stringify({
                                            unit_id: propertyId
                                        })
                                    })
                                        .then(response => {
                                                if (response.status === 200) {
                                                    setAddingGood(true);
                                                    // setCounter(counter++)
                                                    setTimeout(() => setAddingGood(false), 1500);
                                                }
                                            }
                                        )
                                    // fetchAddToCart(propertyId)
                                }
                                // проверяем залогинен ли пользователь и выыбрана ли опция по товара
                                disabled={!(propertyId && isUserAuth)}>
                                Добавить в корзину
                            </button>
                        </div>
                        :
                        <div>
                            {addingGood &&
                                <div style={{color: '#ababab', textAlign: 'center', margin: '15px'}}>Товар
                                    добавлен</div>}
                            {!isUserAuth ?
                                <div style={{color: '#ababab', textAlign: 'center', margin: '15px'}}>Войдите на
                                    сайт,
                                    чтобы добавить товар</div> : null}
                            <button
                                className="GoodItem_add-to-cart goodDetail_addToCartBtn button-yellow"
                                onClick={
                                    async () => {
                                        return await fetchWithAuthCheck('/shops/add-to-cart/', {
                                            method: 'POST',
                                            body: JSON.stringify({
                                                unit_id: mainId
                                            })
                                        })
                                            .then(response => {
                                                    if (response.status === 200) {
                                                        setAddingGood(true);
                                                        // setCounter(counter++)
                                                        setTimeout(() => setAddingGood(false), 1500);
                                                    }
                                                }
                                            )
                                    }
                                }
                                // проверяем залогинен ли пользователь и выыбрана ли опция по товара
                                disabled={!isUserAuth}>
                                Добавить в корзину
                            </button>
                        </div>
                    }
                </div>
            </div>
        </Layout>
    );
};

export default GoodDetail;