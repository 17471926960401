import React from 'react';
import {useLocation} from "react-router-dom";
import Layout from "../../containers/Layout/Layout";
import {useNavigate} from "react-router";
import {getStatusOfOrder} from "../../functions";
import './ActiveOrderItemDetail.css';
import pic1 from '../../assets/images/0001.png';
import pic2 from '../../assets/images/0002.png';
import pic3 from '../../assets/images/0003.png';
import home from '../../assets/images/home.png';
import shop from '../../assets/images/shop.png';

const ActiveOrderItemDetail = () => {
    const location = useLocation();
    const navigate = useNavigate();

    let order = location.state?.order;

    return (
        <Layout>
            <button onClick={() => navigate(-1)}
                    className="back-btn-active-order-item-detail button-grey1">
                Назад
            </button>
            {!order?.self_pickup
                ? <div className="ActiveOrderItemDetail">
                    {/*Выводим эту часть при доставке*/}
                    <div className="ActiveOrderItemDetail_detail-list">
                        <div className="ActiveOrderItemDetail_detail-list-header">Статус заказа: <span
                            className="ActiveOrderItemDetail_order-status">{getStatusOfOrder(order?.status)}</span>
                        </div>
                        <div className="ActiveOrderItemDetail_order-id"> Номер заказа: {order?.id}</div>
                        <ul className="ActiveOrderItemDetail_detail-list-items">
                            <li
                                className={order?.status === 0 || order?.status === 1
                                    ? "ActiveOrderItemDetail_detail-list-item active"
                                    : "ActiveOrderItemDetail_detail-list-item"
                                }>
                                <img className="ActiveOrderItemDetail_detail-list-item-img" src={pic1} alt="icon"/>
                                <div className="ActiveOrderItemDetail_detail-list-item-message">1. Подготовка заказа
                                </div>
                            </li>
                            <li
                                className={order?.status === 2 || order?.status === 3
                                    ? "ActiveOrderItemDetail_detail-list-item active"
                                    : "ActiveOrderItemDetail_detail-list-item"
                                }>
                                <img className="ActiveOrderItemDetail_detail-list-item-img" src={pic2} alt="icon"/>
                                <div className="ActiveOrderItemDetail_detail-list-item-message">2. Доставка в процессе
                                </div>
                            </li>
                            <li
                                className={order?.status === 4 || order?.status === 5
                                    ? "ActiveOrderItemDetail_detail-list-item active"
                                    : "ActiveOrderItemDetail_detail-list-item"
                                }
                            >
                                <img className="ActiveOrderItemDetail_detail-list-item-img" src={pic3} alt="icon"/>
                                <div className="ActiveOrderItemDetail_detail-list-item-message">3. Доставлено вам</div>
                            </li>
                        </ul>
                    </div>
                    <div className="ActiveOrderItemDetail_shop-address">
                        <img src={shop} alt="shop"/>
                        <div>{order?.shop?.address}</div>
                    </div>
                    <div className="ActiveOrderItemDetail_delivery-address">
                        <img src={home} alt="home"/>
                        <div>{order?.customer_address_name}</div>
                    </div>
                    <h3 className="ActiveOrderItemDetail_in-short">Краткая информация</h3>
                    <div className="ActiveOrderItemDetail_shop-name">Магазин: {order?.shop?.name}</div>
                    <div className="ActiveOrderItemDetail_total-price">Общая сумма: {order?.order_total_price} &#8376;</div>
                </div>
                : <div className="ActiveOrderItemDetail">
                    {/*Выводим эту часть при самовывозе*/}
                    <div className="ActiveOrderItemDetail_detail-list">
                        <div className="ActiveOrderItemDetail_detail-list-header">Статус заказа: <span
                            className="ActiveOrderItemDetail_order-status">{getStatusOfOrder(order?.status)}</span>
                        </div>
                        <div className="ActiveOrderItemDetail_order-id"> Номер заказа: {order?.id}</div>
                        <ul className="ActiveOrderItemDetail_detail-list-items">
                            <li
                                className={order?.status === 0 || order?.status === 1
                                    ? "ActiveOrderItemDetail_detail-list-item active"
                                    : "ActiveOrderItemDetail_detail-list-item"
                                }>
                                <img className="ActiveOrderItemDetail_detail-list-item-img" src={pic1} alt="icon"/>
                                <div className="ActiveOrderItemDetail_detail-list-item-message">1. Подготовка заказа
                                </div>
                            </li>
                            <li
                                className={order?.status === 2
                                    ? "ActiveOrderItemDetail_detail-list-item active"
                                    : "ActiveOrderItemDetail_detail-list-item"
                                }>
                                <img className="ActiveOrderItemDetail_detail-list-item-img" src={pic2} alt="icon"/>
                                <div className="ActiveOrderItemDetail_detail-list-item-message">2. Ваш заказ можно
                                    забирать
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div className="ActiveOrderItemDetail_shop-address">
                        <img src={shop} alt="shop"/>
                        <div>{order?.shop?.address}</div>
                    </div>
                    <h3 className="ActiveOrderItemDetail_in-short">Краткая информация</h3>
                    <div className="ActiveOrderItemDetail_shop-name">Магазин: {order?.shop?.name}</div>
                    <div className="ActiveOrderItemDetail_total-price">Общая сумма: {order?.order_total_price} &#8376;</div>
                </div>
            }

        </Layout>
    );
};

export default ActiveOrderItemDetail;