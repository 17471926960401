import React from 'react';
import './Subcategories.css';
import SubcategoryItem from "../SubcategoryItem/SubcategoryItem";
import {useDispatch, useSelector} from "react-redux";
import {fetchGoodsBySubcategory, selectSubcategory} from "../../actions/actions";

const Subcategories = () => {

    const dispatch = useDispatch();

    const subcategories = useSelector(state => {
        const {subcategoriesReducer} = state;
        return subcategoriesReducer.subcategories;
    });

    const onSelectSubcategory = (subcategory) => {
        dispatch(fetchGoodsBySubcategory(subcategory.name))
        dispatch(selectSubcategory(subcategory));
    }

    return (
        // выводим список подкатегорий
        <div className='Subcategories'>
            {subcategories.map(subcategory =>
                <SubcategoryItem
                    className="SubcategoryItem"
                    onSelectSubcategory={onSelectSubcategory}
                    key={subcategory.id}
                    subcategory={subcategory}
                />
            )}
        </div>
    );
};

export default Subcategories;